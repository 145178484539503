import React from 'react'
import Layout from '../components/layout'
import NavigationBar from "../components/navigation-bar"
import { ContactCard } from "../components/card"
import Seo from "../components/seo"
import MenuButton from "../components/menu-button"
import Menu from "../components/menu"
import '../styles/layout.styles.sass'
import '../styles/navigation.styles.sass'
import '../styles/spinner.styles.sass'
import { Link } from 'gatsby'
import Footer from '../components/footer'
const ContactPage = () => {
    const [isOpen, setOpen] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [name, setName] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [isLoading, toggleLoading] = React.useState(false)
    const [infoToUser, setInfo] = React.useState('')

    return (<Layout>
        <Seo title="Contact me" description ="Share an interest? I would love to connect. Send me a message" meta="fullstack, software, developer, amsterdam" />
        <MenuButton page={'contact-page-bar'} open={isOpen} onClickOperation={setOpen} />
        <Menu open={isOpen} onCloseOperation={setOpen} />
        <div className={`page-container contact-page-container ${!isOpen ? 'page-container-open' : 'page-container-close'}`}>
            <div className={'navigation-container-left'}>
                <Link to="/"><NavigationBar title={'About me'} page={'about-me'} /></Link>
                <Link to="/skill"><NavigationBar title={'My skills'} page={'skills'} /></Link>
                <Link to="/blog"><NavigationBar title={'Blogs'} page={'blogs'} /></Link>
                <Link to="/projects"> <NavigationBar title={'Projects'} page={'projects'} /></Link>
            </div>
            <div className={'content-container'}>
                <div className={'layout-container'}>
                    <div className={'title contact-title'}>
                        <em>Contact me</em>
                    </div>
                    <div className={'contact'}>
                        <div className={'contact-message'}>
                            <div className={'contact-message'}>
                                <div className="input">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        value={name}
                                        id="name"
                                        type="text"
                                        pattern="[^()/><\][\\\x22,;|]+"
                                        onChange={(input) => setName(input.target.value)} />
                                </div>
                                <div className="input">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        value={email}
                                        id="email"
                                        type="email"
                                        onChange={(input) => setEmail(input.target.value)} />
                                </div>
                                <div className="input">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        value={message}
                                        id="message"
                                        type="text"
                                        cols="40"
                                        rows="5"
                                        onChange={(input) => setMessage(input.target.value)} />
                                </div>
                                <div style={{ width: '96%', textAlign: 'right' }}>
                                    {!isLoading && <button className={'contact-button'} onClick={() => {
                                        console.log('Clicking...')
                                        toggleLoading(true)
                                        const requestOptions = {
                                            method: 'POST',
                                            headers: { 'Content-Type': 'application/json' },
                                            body: JSON.stringify(
                                                {
                                                    name: name,
                                                    email: email,
                                                    description: message,
                                                }
                                            )
                                        };
                                        fetch('/api/contact', requestOptions)
                                            .then(s => {
                                                toggleLoading(false)
                                                setEmail('')
                                                setName('')
                                                setMessage('')
                                                setInfo('Message is sent.')
                                            })
                                            .catch(e => {
                                                toggleLoading(false)
                                                setEmail('')
                                                setName('')
                                                setMessage('')
                                                setInfo('Message could not be sent, please try again later.')
                                            });

                                    }}>Send</button>}
                                    {isLoading &&
                                        <div className='loader'></div>}
                                    {infoToUser &&
                                        <div>{infoToUser}</div>}
                                </div>
                            </div>
                        </div>
                        <div className={'divider'}>
                            <div className={'divider-item'}>
                            </div>
                        </div>
                        <div className={'contact-social'}>
                            <div className={'row'} >
                                <div className={'title'}>
                                    <div className={'text'}>If you prefer social</div>
                                </div>
                            </div>
                            <div className={'row'} >
                                <ContactCard
                                    link={'https://www.linkedin.com/in/devayan-sarkar/'}
                                    image={'https://firebasestorage.googleapis.com/v0/b/devayansarkar-97d7e.appspot.com/o/linkedin.png?alt=media&token=dec0c2ad-f3e0-4443-8a63-f0b97be23372'}
                                    title={'LinkedIn'} />
                                <ContactCard
                                    link={'https://github.com/devayansarkar'}
                                    image={'https://firebasestorage.googleapis.com/v0/b/devayansarkar-97d7e.appspot.com/o/github_long.png?alt=media&token=d0f6f52b-2210-4726-8b02-092bd653b91f'}
                                    title={'GitHub'}
                                />
                            </div>
                            <div className={'row'}>
                                <ContactCard
                                    link={'mailto:me@devayansarkar.com'}
                                    image={'https://firebasestorage.googleapis.com/v0/b/devayansarkar-97d7e.appspot.com/o/email.png?alt=media&token=3e363075-3a4b-46e7-98dc-92a0b2095fb3'}
                                    title={'Email'} />
                                <ContactCard
                                    link={'https://twitter.com/devayan_sarkar'}
                                    image={'https://firebasestorage.googleapis.com/v0/b/devayansarkar-97d7e.appspot.com/o/twitter.png?alt=media&token=12d21944-8242-4ffa-842c-45bdcfdbecc2'}
                                    title={'Twitter'} />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    </Layout >
    )
}

export default ContactPage